 .newsLetterBanner{width: 450px; height: 50px; position: absolute; bottom: 100px; left:9%; z-index: 10;}

 .newsLetterBanner input{width: 100%; height: 100%; background: #fff; border-radius:60px ; border: 0px; outline: none !important; padding-left: 80px; font-size: 18px; padding-right: 200px;}

.newsLetterBanner  button{padding: 10px 30px !important; border-radius: 50px !important; color: #fff !important; position: absolute; top: 0px; right: 0px; height: 50px !important; font-size: 16px !important; text-transform: capitalize !important; font-weight: 400 !important;}

 .newsLetterBanner svg{    position: absolute;
   top: 12px;
   left: 27px;
   z-index: 10;
   font-size: 22px !important;
   transform: rotate(-21deg);
   opacity: 0.5;}