@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Italiana&family=Lato:wght@100;300;400;700;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');



*{margin: 0px; padding: 0px; box-sizing: border-box;}
body{font-family: 'Lato', sans-serif !important; font-size: 22px !important; }

.container-fluid{padding: 0px 45px !important;}


.no-click{pointer-events: none !important;}

.transition{transition: all 0.3s ease-in-out;}
.cursor{cursor: pointer;}
.btn{border-radius: 8px !important;}
.btn-border{border: 1px solid rgba(0,0,0,0.1) !important; padding: 10px 25px !important; border-radius: 5px !important; color: #000 !important;}
.btn-border svg{opacity: 0.5;}
.btn-border:hover{border: 1px solid #35ac75 !important; background: #35ac75 !important; color: #fff !important;}
.btn-border:hover svg{opacity: 1 !important;}
form .btn-g .MuiCircularProgress-root{width: 25px !important; height: 25px !important;}
form .btn-g .MuiCircularProgress-root svg{color: #fff !important;}
.btn-g a{color: #fff !important; text-decoration: none !important;}
.btn-g{background: #35ac75 !important;
  padding: 7px 21px !important;
  border-radius: 5px !important;
  color: #fff !important;
  text-transform: capitalize !important;
  font-size: 12px !important;}
  
.btn-g:hover,button.bg-g:hover{background: #e53840 !important;}
.btn-lg{font-size: 16px !important;
  padding: 6px 17px !important;}
.bg-g,.bg-success{background: #35ac75 !important;}

.text-g{color: #35ac75;}
.text-org{color: #FDC040 !important;}
h1.text-light,h2.text-light,h3.text-light,h4.text-light,h5.text-light,h6.text-light,p.text-light,span.text-light,div.text-light{color: #8b8b8b !important;}
p{color: #7E7E7E;}

.hd{font-size: 19px; font-weight: 600; margin-bottom: 15px;}

/* START TOOLTIP STYLES */
[tooltip] {
    position: relative; /* opinion 1 */
  }
  
  /* Applies to all tooltips */
  [tooltip]::before,
  [tooltip]::after {
    text-transform: none; /* opinion 2 */
    font-size: 12px; /* opinion 3 */
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;
  }
  [tooltip]::before {
    content: '';
    border: 5px solid transparent; /* opinion 4 */
    z-index: 1001; /* absurdity 1 */
  }
  [tooltip]::after {
    content: attr(tooltip); /* magic! */
    
    /* most of the rest of this is opinion */
    font-family: Helvetica, sans-serif;
    text-align: center;
    
    /* 
      Let the content set the size of the tooltips 
      but this will also keep them from being obnoxious
      */
    min-width: 3em;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1ch 1.5ch;
    border-radius: .3ch;
    box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
    background: #3bb77e;
    color: #fff;
    z-index: 1000; /* absurdity 2 */
  }
  
  /* Make the tooltips respond to hover */
  [tooltip]:hover::before,
  [tooltip]:hover::after {
    display: block;
  }
  
  /* don't show empty tooltips */
  [tooltip='']::before,
  [tooltip='']::after {
    display: none !important;
  }
  
  /* FLOW: UP */
  [tooltip]:not([flow])::before,
  [tooltip][flow^="up"]::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: #3bb77e;
  }
  [tooltip]:not([flow])::after,
  [tooltip][flow^="up"]::after {
    bottom: calc(100% + 5px);
  }
  [tooltip]:not([flow])::before,
  [tooltip]:not([flow])::after,
  [tooltip][flow^="up"]::before,
  [tooltip][flow^="up"]::after {
    left: 50%;
    transform: translate(-50%, -.5em);
  }
  
  /* FLOW: DOWN */
  [tooltip][flow^="down"]::before {
    top: 100%;
    border-top-width: 0;
    border-bottom-color: #333;
  }
  [tooltip][flow^="down"]::after {
    top: calc(100% + 5px);
  }
  [tooltip][flow^="down"]::before,
  [tooltip][flow^="down"]::after {
    left: 50%;
    transform: translate(-50%, .5em);
  }
  
  /* FLOW: LEFT */
  [tooltip][flow^="left"]::before {
    top: 50%;
    border-right-width: 0;
    border-left-color: #333;
    left: calc(0em - 5px);
    transform: translate(-.5em, -50%);
  }
  [tooltip][flow^="left"]::after {
    top: 50%;
    right: calc(100% + 5px);
    transform: translate(-.5em, -50%);
  }
  
  /* FLOW: RIGHT */
  [tooltip][flow^="right"]::before {
    top: 50%;
    border-left-width: 0;
    border-right-color: #3bb77e;
    right: calc(0em - 5px);
    transform: translate(.5em, -50%);
  }
  [tooltip][flow^="right"]::after {
    top: 50%;
    left: calc(100% + 5px);
    transform: translate(.5em, -50%);
  }
  
  /* KEYFRAMES */
  @keyframes tooltips-vert {
    to {
      opacity: .9;
      transform: translate(-50%, 0);
    }
  }
  
  @keyframes tooltips-horz {
    to {
      opacity: .9;
      transform: translate(0, -50%);
    }
  }
  
  /* FX All The Things */ 
  [tooltip]:not([flow]):hover::before,
  [tooltip]:not([flow]):hover::after,
  [tooltip][flow^="up"]:hover::before,
  [tooltip][flow^="up"]:hover::after,
  [tooltip][flow^="down"]:hover::before,
  [tooltip][flow^="down"]:hover::after {
    animation: tooltips-vert 300ms ease-out forwards;
  }
  
  [tooltip][flow^="left"]:hover::before,
  [tooltip][flow^="left"]:hover::after,
  [tooltip][flow^="right"]:hover::before,
  [tooltip][flow^="right"]:hover::after {
    animation: tooltips-horz 300ms ease-out forwards;
  }
  



.price{font-size:17px; margin-right: 20px;}
.oldPrice{font-size: 18px; opacity: 0.6; color: #000; text-decoration: line-through;}


.listingPage{width: 100%; height: auto; padding:20px 0px 45px 0px;}

.breadcrumb{width: 100%; height: auto; padding: 25px 45px !important; background: #d8f1e5 !important; border-radius: 20px !important; margin-bottom: 30px;}
.breadcrumb h1{color: #000; opacity: 0.8; font-size: 26px; font-weight: bold; margin-bottom: 0px;}
.breadcrumb ul li{margin-right: 20px !important;}
.breadcrumb ul li a{color: #000; text-decoration: none; font-size: 15px;}


.sidebarWrapper{max-width: 20%; flex: 0 0 20%;}
.rightContent{max-width: 80%; flex: 0 0 80%;}
.sidebarWrapper h3{font-size: 15px; font-weight: 600; position: relative; padding-bottom: 20px; margin-bottom: 10px;}
.sidebarWrapper h3:after{content: ''; width: 100%; height: 3px; background: #f0f0f0; position: absolute; bottom: 0px;
left: 0px;}

.sidebarWrapper h3:before{content: ''; width: 20%; height: 3px; background: #b0e5c2; position: absolute; bottom: 0px; left: 0px;  z-index: 10;}

.sidebarWrapper .sidebar{position: sticky; top: 110px;}
.sidebarWrapper .sidebar img{height: auto !important;}

.sidebarWrapper .sidebar .card{padding: 25px; border-radius: 10px !important; margin-bottom: 40px;}

.sidebarWrapper .sidebar .card .catList a{text-decoration: none;}
.sidebarWrapper .sidebar .card .catList .catItem{ padding:7px 10px; border: 1px solid rgba(0,0,0,0.050); cursor: pointer; margin: 10px 0px; border-radius: 4px; transition:all 0.3s ease-in-out}
.sidebarWrapper .sidebar .card .catList .catItem h4{color: #000 !important; font-size: 14px; }
.sidebarWrapper .sidebar .card .catList .catItem .rounded-circle{background: #BCE3C9; width: 27px; height: 27px; font-size: 12px;}
.sidebarWrapper .sidebar .card .catList .catItem:hover{border: 1px solid rgba(0,0,0,0.2);}

.sidebarWrapper .sidebar .card .catList .catItem .img img{width: 22px !important;}

.priceRange span{font-size: 13px;}

.filters{padding: 25px 0px;}
.filters h5{font-weight: 600; margin-bottom: 10px; font-size: 15px;}
.filters ul{max-height: 200px; overflow-y: scroll; margin-left: -10px;}
.filters ul::-webkit-scrollbar{width: 10px !important;}
.filters ul::-webkit-scrollbar-thumb{ background: #ccc !important;}
.filters ul li{width: 100%; margin-bottom: 0px; list-style: none;}

.MuiRadio-root  svg{width: 15px !important; height: 15px !important;}

.filterWrapper{display: none !important;}
.sidebarWrapper .sidebar  .btn{width: 100% !important;}



.rightContent .topStrip{padding: 0px 25px;}
.rightContent .topStrip p{font-size: 16px;}

.rightContent .topStrip .tab_{width: 200px;}
.rightContent .topStrip .tab_ .btn_{background: none !important; border: 1px solid rgba(0,0,0,0.1) !important; padding: 10px 20px !important; text-transform: capitalize !important; color: rgba(0,0,0,0.7) !important; border-radius: 10px !important; font-size: 16px !important; width: 100% !important; justify-content: flex-start !important;}
.rightContent .topStrip .tab_ .btn_ svg{opacity: 0.5;}
.rightContent .topStrip .tab_ .dropdownMenu{top: 100% !important;}

.breadcrumbWrapper{width: 100%; height: auto; padding: 20px 0px;  
  border-bottom:1px solid rgba(0,0,0,0.1) !important ;}
.breadcrumb2{background: none !important; padding: 0px !important;}
ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
  background-color: #eee;
}
ul.breadcrumb li {
  display: inline;
  font-size: 14px;
}
ul.breadcrumb li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}
ul.breadcrumb li a {
  color: #3bb77e;
  text-decoration: none !important;
}
ul.breadcrumb li a:hover {
  color: #3bb77e;
  text-decoration: underline;
}



  
.detailsContainer{max-width: 85% !important; margin: auto; padding: 60px 0px;}

.detailsPage .productInfo{padding-left: 80px;}
.detailsPage .productInfo h1{font-size: 30px; font-weight: bold; opacity: 0.8;}
  .detailsPage .productInfo .priceSec .priceLarge{font-size: 28px; font-weight: bold;}
  .detailsPage .productInfo .priceSec .text-org{font-size: 18px; font-weight: 600;}
  .detailsPage .productInfo .priceSec .text-light.oldPrice{font-size: 16px; font-weight: 600;}

  .detailsPage .productInfo .MuiRating-root{font-size: 18px !important;}

  .reviewTxt{font-size: 16px;}

  p{font-size: 16px;}

  .productZoom{ width: 100%; height: 70vh; border: 1px solid rgba(0,0,0,0.1); padding: 0px; overflow: hidden; border-radius: 15px;}
  .zoomSliderBig{width: 100% !important;}

  .slick-arrow{background: #fff;}
  .zoomSlider{padding-top: 30px;}
  .zoomSlider .slick-slide { padding-right: 10px !important;}
  .zoomSlider *{outline: none !important;}
  .zoomSlider .item{border: 2px solid #e4e4e4 !important; overflow: hidden; border-radius: 10px; cursor: pointer; padding: 3px; height: 80px;}
  .zoomSlider .item img{height: 100% !important; object-fit: cover !important;}
  .zoomSlider .slick-current .item{border: 2px solid #3bb77e !important; }
  .zoomSlider .slick-arrow{top: 55% !important; zoom: 80% !important;}
  .zoomSlider .slick-arrow.slick-next{right: -2% !important;}
  .zoomSlider .slick-arrow.slick-prev{left: -5% !important;}


  .productSize{width: 100%; height: auto; padding: 25px 0px;}
  .productSize span{font-size: 14px;}
  .productSize ul li{margin-right: 10px !important;}
  .productSize ul li a{display: flex; align-items: center; justify-content: center; padding:5px 10px !important; border: 1px solid rgba(0,0,0,0.1); border-radius: 5px !important; cursor: pointer; text-decoration: none !important; color: #000; font-size: 12px !important; transition: all 0.1s ease-in-out;}
  .productSize ul.error a{border: 1px solid red !important; }
  .productSize ul li a:hover{background: #f1f1f1;}
  
  .productSize ul li a.active{background: #3bb77e !important; color: #fff !important;}

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  .addCartSection{height: auto;}
  .addCartSection .counterSec{width: 90px; height: 45px; border-radius: 10px; border: 1px solid #3bb77e !important; overflow: hidden; padding: 10px;}
  .addCartSection .counterSec input{width:45px; height: 40px; padding: 5px; border: 0px; outline: none !important; text-align: center; pointer-events: none; position: relative; top: -8px; font-size: 15px;}

  .addCartSection .counterSec{position: relative;}
  .addCartSection .counterSec .arrow{position: absolute;
    cursor: pointer;
    opacity: 0.6;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;}
  .addCartSection .counterSec .arrow.plus{top: 0px; right: 10px;}
  .addCartSection .counterSec .arrow.minus{bottom: 0px; right: 10px;}

  .addtocartbtn{min-width: 50px !important; padding: 10px 15px !important;}
  .addtocartbtn svg{font-size: 21px !important;}
  button.btn-g.addtocartbtn {width:150px !important;}

  .addCartSection button{height: 50px; min-width: inherit !important;}
  .addCartSection button svg{font-size: 30px !important;}
  

  
.quantityDrop{width: 150px; gap: 10px;}
.quantityDrop button{min-width: 35px !important; width: 35px !important; height: 35px !important; border-radius: 50% !important; background: #edeef5 !important; border: 1px solid rgba(0,0,0,0.1) !important; color: #000;}
.quantityDrop button svg{color: #000 !important;}

.quantityDrop button:hover{background: #ccc !important;}

.quantityDrop input{width: 30px; border: 0px; background:transparent; outline: none !important;
text-align: center; font-size: 17px;}


.card{border-radius: 10px !important;}

  .detailsPageTabs{border-radius: 25px !important;}

  .customTabs ul li{margin-right: 30px !important;}
  .customTabs ul li button{padding: 7px 25px !important; border: 1px solid rgba(0,0,0,0.2) !important; font-size: 14px !important; text-transform: capitalize !important; border-radius: 30px !important; color: #000 !important;}

  .customTabs ul li button.active{color: #3bb77e !important;}

  .customTabs .tabContent tr th, .customTabs .tabContent tr td{font-size: 13px; vertical-align: middle; padding:10px 10px !important;}

  .customTabs .tabContent tr td p{margin-bottom: 0px !important;}

  .tabContent h3{font-size: 20px !important;}
  .reviewForm h4{font-size: 18px !important;}

  .reviewsCard{margin-bottom: 25px !important;}

  .reviewsCard .image{width: 100px;}
  .reviewsCard .image span{font-size: 16px; display: block; text-align: center;}
  .reviewsCard .rounded-circle{display: flex; align-items: center; justify-content: center; overflow: hidden; width: 60px; height:60px; margin: auto;}
  .reviewsCard .rounded-circle img{width: 100%; height: 100%; object-fit: cover;}
  .reviewsCard  .info{width: 85%;}

  a:hover{text-decoration: none; color: #000;}

  .reviewsScroll{max-height: 300px; overflow-y: scroll; margin-bottom: 25px; padding-right: 25px;}
  .reviewsScroll::-webkit-scrollbar{width: 8px; height: 8px;}
  .reviewsScroll::-webkit-scrollbar-thumb{background: #ccc;}
  .reviewsScroll::-webkit-scrollbar-track{background: #f1f1f1;}

  .reviewForm .form-group{margin-bottom: 25px;}
  .reviewForm .form-group .form-control{height: 50px; font-size: 15px; border-radius: 7px !important; padding-left: 20px;}
  .reviewForm .form-group textarea.form-control{height: 120px; padding-top: 25px;}

  .progressBarBox{margin-bottom: 10px;}
  .progress-bar{font-size: 16px;}


  .relatedProducts  .item {
    padding: 15px 5px;
    padding-right: 25px !important;
}

.loader{position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 10000; background: #fff; display: flex; align-items: center; justify-content: center;}






.homeProducts{ padding: 25px 0px; padding-top: 0px;}

.filterTab li{margin-left: 20px; position: relative;}
.filterTab li a{color: #000; text-decoration: none; font-size: 14px; transition: all 0.3s ease-in-out; position: relative; font-weight: 600;}
.filterTab li:hover a, .filterTab li a.act{color: #3bb77e !important; transform: translateY(-10px); }

.homeProducts .productRow{ display: flex; padding: 30px 0px; padding-top: 15px; margin: 0px -15px; flex-wrap: wrap;}
.homeProducts .productRow.loading{opacity: 0.5; filter: blur(5px);}

.homeProducts .productRow .item{width: 25%;  padding: 5px 5px;}
.homeProductWrapper .productRow .item{width: 20%;}

.homeProductsRow2 .prodSlider .item{padding:0px 5px;  padding-right: 5px  !important;}


.topProductsSection{padding: 35px 0px;}




.bestseller .col-md-3 img{height: auto !important;}



.slick-arrow{ position: absolute; top: 47%; width: 60px !important; height: 60px !important; border-radius: 50% !important; background: #F2F3F4 !important; z-index: 10 !important; transition: all 0.2s ease-in-out; box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);}
.slick-arrow.slick-next{right: 2% !important;}
.slick-arrow.slick-prev{left: 2% !important;}

.slick-prev:before{content: "\f104" !important; font-family: FontAwesome !important; font-size: 30px !important; color: #000 !important;}
.slick-next:before{content: "\f105" !important; font-family: FontAwesome !important; font-size: 30px !important; color: #000 !important;}

.slick-arrow:hover{background: #3bb77e !important;}
.slick-arrow:hover::before{color: #fff !important;}

.home_slider_Main .slick-dots{position: absolute; bottom: 30px !important;}
.home_slider_Main .slick-dots button::before{width: 6px !important; height: 6px !important; font-size: 18px !important;}
.slick-active button::before{color: #3bb77e !important;}



.horizontal-shake {
  animation: horizontal-shaking 0.35s;
}

@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
}




.checkoutPage{padding: 55px 0px;}
.checkoutPage .container{max-width: 75%;}
.checkoutPage .form{zoom: 135%; background: #f1f1f1; padding: 40px;}
.checkoutPage .form button{zoom: 80%;}
.checkoutPage .card{background: #f1f1f1 !important;}

.range-slider{height: 5px !important;}
.range-slider .range-slider__thumb{width: 15px !important; height: 15px !important;}



.loginWrapper{padding: 55px 0px; background: #f1f1f1;}
.loginWrapper .card{width: 500px; margin: auto; padding: 40px; border: 0px !important;}
.loginWrapper .card h3{font-weight: 500; font-size: 25px;}
form input{height: 22px !important; font-size: 13px !important; color: #000 !important;}
form fieldset{border-radius: 10px !important;}
form .MuiFormLabel-root{line-height: 23px !important; color: #000 !important; font-size: 13px !important;}

.loginWrapper .card .icon{position: absolute; top: 2px; right: 10px; z-index: 100; min-width:50px !important; height:50px !important; width:50px !important; color: #ccc !important; border-radius: 100% !important;}
.loginWrapper .card .icon svg{color: #000 !important; opacity: 0.8; font-size: 18px !important;}

.loginWrapper .card button{padding: 15px 25px !important;}
.signInOr button{padding: 10px 25px !important; color: #000 !important; font-size: 13px !important;}
.signInOr button img{width: 20px; margin-right: 15px;}


.formLoader{position: absolute !important; background: rgba(255,255,255,0.5) !important;}


.sidebar .scroll {
  max-height: 180px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-left: 10px;
}

.sidebar  .catList{max-height: 250px; overflow-y: scroll; padding-right: 10px;}
.sidebar  .catList::-webkit-scrollbar{width: 7px !important; height: 7px !important;}
.sidebar  .catList::-webkit-scrollbar-thumb{background: #ccc !important;}
.sidebar  .catList::-webkit-scrollbar-track{background: #f1f1f1 !important;}

.sidebar .scroll::-webkit-scrollbar{width: 5px !important;}
.sidebar .scroll::-webkit-scrollbar-thumb{ background: #ccc !important;}

.productRow .loading{width: 100%; height: 400px; display: flex; align-items: center; justify-content: center;}


.loadingOverlay{position: fixed; top: 0px; left: 0px; width: 100%; height:100%; z-index: 10000; cursor: wait; background: rgba(255,255,255,0.7);}

.myListTableWrapper{width: 70%; margin: auto; padding: 45px 0px;}
.myListTable .cartItemimgWrapper{width: 500px !important;}
.myListTable .cartItemimgWrapper .imgWrapper{width: 100px !important; height: 100px !important;}

button.wishlist  svg{opacity: 1 !important;}

.cartItemimgWrapper{width: 280px;}
  .cartItemimgWrapper .imgWrapper{width: 20%; height: 80px; overflow: hidden;}  
  .cartItemimgWrapper .imgWrapper img{width: 100%; height: 100%; object-fit: cover;} 
  .cartItemimgWrapper  .info{width: 80%;}
  .cartItemimgWrapper  .info a:hover{text-decoration: none;}
  .cartItemimgWrapper  .info h6{color: #343434; font-weight: bold;}
  .cartItemimgWrapper  .info h6:hover{color: #ed174a   !important;}
  .remove{cursor: pointer;}
  .remove svg{font-size: 30px;}
  .remove:hover svg{color: #ed174a   !important;}

  .cartPage .quantityDrop button{zoom: 75%;}
  .cartPage table th,  .cartPage table td{vertical-align: middle;}

  .cartPage .table td,   .cartPage .table th{border-top: 0px !important;  border-bottom: 1px solid rgba(0,0,0,0.1) !important;}

  .cartPage table tbody tr:hover{background: #fafafa;}


  
  .cartPage .table thead{background: #e3e3e3 !important;overflow: hidden;}
  .cartPage .table thead th{border: 0px !important; font-size: 15px !important;}
  .cartPage .table thead th:first-child{border-top-left-radius: 10px !important; 
    border-bottom-left-radius: 10px !important;}
    .cartPage .table thead th:last-child{border-top-right-radius: 10px !important; 
      border-bottom-right-radius: 10px !important;}



  
.quantityDrop{width: 150px; gap: 10px;}
.quantityDrop button{min-width: 40px !important; width: 40px !important; height: 40px !important; border-radius: 50% !important; background: #edeef5 !important; border: 1px solid rgba(0,0,0,0.1) !important; color: #000;}
.quantityDrop button svg{color: #000 !important;}

.quantityDrop button:hover{background: #ccc !important;}

.quantityDrop input{width: 30px; border: 0px; background:transparent; outline: none !important;
text-align: center;}    


.loadingOverlay{position: fixed; top: 0px; left: 0px; width: 100%; height:100%; z-index: 10000; cursor: wait; background: rgba(255,255,255,0.7);}


.cartRightBox{padding-left: 50px !important;}
.cartRightBox .card{padding: 20px !important; background: #fafafa;}
.cartRightBox .card .hd{font-size: 16px !important; margin-bottom: 0px;}
.cartRightBox .card thead th{padding-left: 0px !important; font-size: 14px !important;}
.cartRightBox .card td{padding-left: 0px !important; font-size: 14px !important;}


.orderPage{padding: 30px 0px;}
.orderPage .table-responsive::-webkit-scrollbar{width: 8px !important; height: 8px !important;}
.orderPage .table-responsive::-webkit-scrollbar-thumb{background: #ccc !important;}
.orderTable th{font-size: 14px !important; white-space: nowrap;}
.orderTable td{font-size: 14px !important; white-space: nowrap;}



.productModal .actions button{opacity:  1 !important;}
.productModal .actions button svg.text-danger{color: #ed174a  !important; opacity: 1 !important;}

.productModal .MuiPaper-elevation{ padding: 25px; position: relative; max-width: 900px !important; width: 900px !important;}

.productModal h4{font-size: 20px !important; color: rgba(0,0,0,0.8) !important;}


.close_{position: absolute !important; top: 10px; right: 10px; color: #000 !important; min-width: 40px !important; width: 40px !important; height: 40px !important; border-radius: 50% !important; background: #f1f1f1 !important;}

.close_ svg{font-size: 22px; color: #000 !important; opacity: 0.7;}

.orderInfo{background: #fafafa !important; padding: 25px !important;}
.orderInfo thead{ border-top: 1px solid rgba(0,0,0,0.1) !important; border-bottom: 1px solid rgba(0,0,0,0.1) !important;}


.tableFooter{padding-bottom: 20px;}
.tableFooter p{margin-bottom: 0px; font-size: 14px; font-weight: 500;}
.tableFooter .pagination{margin-left: auto;}

.tableFooter p{ font-size: 14px; font-weight: 500; margin-bottom: 0px;}

.orderTable th,.orderTable td{white-space: nowrap;}

.orderTable .img{width: 50px; height: 60px; overflow: hidden;}
.orderTable .img img{width: 100%;}

.text-blue{color: rgb(7, 132, 248) !important;}


.userImage{width: 140px; height: 140px; overflow: hidden; border-radius: 100%; border: 2px solid #0858f7; position: relative;}
.userImage img{width: 100%; height: 100%; object-fit: cover;}
.userImage .overlay{width: 100%; height: 100%; position: absolute; top: 0px; left: 0px; background: rgba(0,0,0,0.6); z-index: 1000; cursor: pointer;transition: all 0.2s ease-in-out; opacity: 0;}

.userImage .overlay svg{font-size: 40px; color: #fff;}
.userImage:hover .overlay{opacity: 1;}
.userImage .overlay input{position: absolute; top: 0px; left: 0px; width: 100%; height: 100% !important; z-index: 1000; opacity: 0;}


.myAccBox .form-group{margin-bottom: 20px;}
.myAccBox .form-group .MuiOutlinedInput-input{background: #f1f1f1 !important;}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.myAccountPage{padding: 25px 0px;}