header{width: 100%; height:auto; margin: auto; padding: 10px 0px; }

header .part1 .logo{width:200px; height: auto !important;}

.headerWrapper{transition: all 0.3s ease-in-out; background: #fff; position: fixed; top: 0px; left: 0px; width: 100%; z-index: 1000;}
.headerWrapper.fixed{ top: -66px;  }
header .headerSearch{width: 100%; height: 45px; border: 1px solid rgba(0,0,0,0.1); padding: 10px 15px; border-radius: 4px; }
header .headerSearch .selectDropWrapper{width: 30%; font-size: 16px; font-weight: 500; position: relative;}
.selectDropWrapper svg{color: #000 !important; opacity: 0.7 !important;}
header .headerSearch .selectDropWrapper:after{content: ''; width: 1px; height: 30px; background: rgba(0,0,0,0.3); position: absolute; top: 5px; right: 0px;}
header .headerSearch .search{width:100%; padding-left: 5px; position: relative;}
header .headerSearch .search input{width: 100%; height: 38px; border: 0px; outline: none !important; font-size: 14px; background: none !important; color: rgba(0,0,0,0.8);}
header .headerSearch .search input::-webkit-input-placeholder{ color: rgba(0,0,0,0.8);}
header .headerSearch .search input::-moz-placeholder{ color: rgba(0,0,0,0.8);}
header .headerSearch .search .searchIcon{position: absolute;
    top: 9px;
    right: 0px;
    font-size: 23px !important; color: rgba(0,0,0,0.8);}

    header .headerSearch .search .searchIcon.loading_{width: 25px !important; height:25px !important;}

.afterHeader{margin-top: 125px;}


 .countryWrapper .selectDropWrapper{width: 200px !important; height: 45px;  padding-left: 10px; display: flex; align-items: center;      border: 1px solid rgba(0,0,0,0.1);}
 .countryWrapper .selectDropWrapper .openSelect{width: 100%; font-weight: 600;}
 .countryWrapper .selectDropWrapper .selectDrop{left:0px !important;}
 .countryWrapper  .openSelect{color:#279a65 !important ;}


header .headerTabs{padding-left: 16px;}
header .headerTabs li{position: relative; margin-left: 16px;}
header .headerTabs li span{font-size: 13px; cursor: pointer; text-decoration: none; color: rgba(0,0,0,0.8);}
header .headerTabs li span:hover{text-decoration: none;}
header .headerTabs li span *{color: rgba(0,0,0,0.80) !important;}
header .headerTabs li span a:hover{text-decoration: none;}
header .headerTabs li span svg{margin-right: 10px; width: 22px !important; height: auto !important;}
header .headerTabs li span.badge{width: 20px;
    height: 20px;
    color: #fff !important;
    font-weight: 400;
    position: absolute;
    top: -2px;
    left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px !important;}

.dropdownMenu{position: absolute; top:50px; right: 0px; width: 200px; height: auto; background: #fff;  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); padding: 10px 0px; z-index: 100;}
.dropdownMenu li{width: 100% !important; list-style: none; margin: 0px !important;}
.dropdownMenu li button{width: 100% !important; text-align: left !important; justify-content: flex-start !important; color: rgba(0,0,0,0.7) !important; text-transform: capitalize !important; font-size: 13px !important; padding: 7px 15px !important;}

.dropdownMenu li button svg{opacity: 0.7; margin-right: 8px !important;}
.dropdownMenu li button:hover{background: #f1f1f1 !important;}