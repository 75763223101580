.homeProducts {
  padding: 25px 0px;
}

.filterTab li {
  margin-left: 20px;
  position: relative;
}

.filterTab li a {
  color: #000;
  text-decoration: none;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  position: relative;
  font-weight: 600;
}

.filterTab li:hover a,
.filterTab li a.act {
  color: #3bb77e !important;
  transform: translateY(-10px);
}

.homeProducts .productRow {
  display: flex;
  padding: 30px 0px;
  margin: 0px -15px;
  flex-wrap: wrap;
}

.homeProducts .productRow.loading {
  opacity: 0.5;
  filter: blur(5px);
}

.homeProducts .productRow .item {
  width: 25%;
  padding: 5px 5px;
}

.homeProductWrapper .productRow .item {
  width: 20%;
}

.homeProductsRow2 .prodSlider .item {
  padding: 0px 5px;
}

.topProductsSection {
  padding: 35px 0px;
}

.newsLetterSection {
  width: 100%;
  height: auto;
}

.newsLetterSection .box {
  background-image: linear-gradient(to bottom right, #d5ffec, #edfff7);
  width: 100%;
  height: auto;
  padding: 60px 60px;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
}

.newsLetterSection .box .info {
  width: 55%;
}

.newsLetterSection .box .info h2 {
  font-size: 38px;
  font-weight: 600;
  line-height: 50px;
  opacity: 0.8;
}

.newsLetterSection .box .info p {
  font-size: 22px;
  opacity: 0.8;
}

.newsLetterSection .box .img {
  width: 35%;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.newsLetterSection .box .img img {
  width: 100%;
}

.newsLetterSection .box .newsLetterBanner {
  position: relative !important;
  left: 0px !important;
  bottom: inherit !important;
}

@media (max-width: 1000px) {
  .homeProducts .productRow .item {
    width: 50%;
  }

  .homeProductWrapper .productRow .item {
    width: 50%;
  }

  .filterTab li a {
    font-size: 16px;
  }

  .newsLetterSection .box .info h2 {
    font-size: 28px;
    line-height: 40px;
  }

  .newsLetterSection .box .info p {
    font-size: 18px;
  }

  .homeProductsRow2 .prodSlider .item {
    width: 50%;
  }
}

@media (max-width: 600px) {
  .homeProducts .productRow .item {
    width: 100%;
  }

  .homeProductWrapper .productRow .item {
    width: 100%;
  }

  .newsLetterSection .box {
    padding: 30px 30px;
  }

  .newsLetterSection .box .info {
    width: 100%;
  }

  .newsLetterSection .box .img {
    display: none;
  }

  .homeProductsRow2 .prodSlider .item {
    width: 100%;
  }
}