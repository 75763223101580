.footerWrapper{padding: 45px 0px; width: 100%; padding: 20px;}
.footerWrapper .footerBoxes .col{padding: 0px 7px;}
.footerWrapper .footerBoxes .col:first-child,.footerWrapper .footerBoxes .col:last-child{padding: 0px;}
.footerWrapper .footerBoxes .box{background: #F4F6FA; padding: 25px 20px; border-radius: 15px; } 
.footerWrapper .footerBoxes .box .info{padding-left: 15px;}
.footerWrapper .footerBoxes .box  img{position: relative; transition: all 0.2s ease-in-out;
width: 40px;}
.footerWrapper .footerBoxes .box .info h4{font-size: 15px; font-weight: 600;}
.footerWrapper .footerBoxes .box .info p{margin-bottom: 0px; font-size: 15px; opacity: 0.8; }
.footerWrapper .footerBoxes .box:hover img{transform: translateY(-5px);}

footer p, footer a{font-size: 14px;}

footer{padding: 40px 0px; padding-bottom: 0px;}

footer .part1 img{width: 200px !important; }

footer .part1 svg{color: #35ac75  !important;}

footer  h3{color: #000; font-weight: 600; margin-bottom: 18px; font-size: 16px;}
footer .part2 ul li{list-style: none;}
footer .part2 ul li a{color:#000; opacity: 0.8; font-size: 500; transition: all 0.2s ease-in-out; font-size: 14px;}
footer .part2 ul li a:hover{text-decoration: none;color: #24935f  !important; opacity: 1; padding-left: 5px;}

footer .lastStrip{padding: 5px 0px;}

.phNo svg{ font-size: 35px !important; opacity: 0.8;}
.phNo h3{ font-size:25px !important; line-height: 35px !important;}
.phNo p{ font-size: 15px !important; opacity: 0.8;}

footer .lastStrip .part3 h5{margin-bottom: 0px; font-size: 18px;}
footer .lastStrip .part3 ul{margin-bottom: 0px; padding-left: 25px;}
footer .lastStrip .part3 ul li a{display: flex; align-items: center; justify-content: center; width: 35px; height: 35px; background: #35ac75  ; border-radius: 50%; transition: all 0.2s ease-in-out !important;}
footer .lastStrip .part3 ul li a svg{color: #fff !important; font-size: 20px;}
footer .lastStrip .part3 ul li a:hover{background:rgb(51, 51, 51);}