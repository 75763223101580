.catSliderSection{width: 100%; height: auto; padding: 0px 0px; padding-bottom: 0px;}
.cat_slider_Main .slick-slide{padding-right: 15px !important;}
.cat_slider_Main .slick-list{padding-left: 10px !important;}
.cat_slider_Main .item{ height: auto;  display: flex; align-items: center; justify-content: center; cursor: pointer;padding: 15px 0px; }

.cat_slider_Main .item .info{padding:0px; width:120px; height: 120px; border-radius: 100%;
    transition:all 0.2s ease-in-out; border: 1px solid rgba(0,0,0,0.1); overflow: hidden;
    margin: auto; display: flex; align-items: center; justify-content: center; transition: all 0.3s ease-in-out;}

    .cat_slider_Main .item:hover .info{transform: translateY(-7px);} 

.cat_slider_Main .item a{text-decoration: none;}
.cat_slider_Main .item  h5{font-weight: 600; font-size: 16px; color: #000; opacity: 0.8; text-transform: capitalize;  width: 100%; text-align: center; padding-top: 10px;}
.cat_slider_Main .item .info p{font-size: 14px; opacity: 0.8; color: #000 !important; margin-bottom: 0px;}
.cat_slider_Main .item img{width: 60% !important;}

.cat_slider_Main .slick-arrow{zoom: 80%;}
.cat_slider_Main .slick-arrow.slick-next{top: -50px !important; right: 15px !important;}

.cat_slider_Main .slick-arrow.slick-prev{top: -50px !important; right: 100px !important; left: inherit !important;}

.cat_slider_Main .item:hover .info{box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);}
